import React from "react"
import PropTypes from "prop-types"
import Image from "gatsby-image"
import { Link } from "gatsby"
import { FaWindows, FaLinux } from "react-icons/fa"

const Box = ({ id, Description, Title, Os, Slug, image }) => {
  let operating_system = <FaWindows className="box-icon" />

  Object.keys(Os).map(function (title) {
    if (Os[title] === "Linux") {
      operating_system = <FaLinux className="box-icon" />
    }
  })

  return (
    <Link to={`/boxes/${Slug}`} className="blog" key={id}>
      <article className="blog">
        {image && (
          <Image fluid={image.childImageSharp.fluid} className="blog-img" />
        )}
        <div className="blog-card">
          <h4>{Title}</h4>
          <p>{Description}</p>
          <div className="blog-footer">
            <p>{operating_system}</p>
          </div>
        </div>
      </article>
    </Link>
  )
}

Box.propTypes = {
  Title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  Os: PropTypes.arrayOf(PropTypes.object).isRequired,
  Description: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  Slug: PropTypes.string.isRequired,
}

export default Box
