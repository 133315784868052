import React from "react"
import Title from "./Title"
import Box from "./Box"
import { Link } from "gatsby"
const Boxes = ({ boxes, title, showLink }) => {
  return (
    <section className="section boxes">
      <Title title={title} />
      <div className="section-center boxes-center">
        {boxes.map((box, index) => {
          return <Box key={box.id} index={index} {...box} />
        })}
      </div>
      {showLink && (
        <Link to="/boxes" className="btn center-btn">
          boxes
        </Link>
      )}
    </section>
  )
}

export default Boxes
