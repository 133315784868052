import React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import Boxes from "../components/Boxes"
import SEO from "../components/SEO"

const Box = ({
  data: {
    allStrapiBoxes: { nodes: boxes },
  },
}) => {
  return (
    <Layout>
      <SEO title="Box" />
      <section className="box-page">
        <Boxes boxes={boxes} title="boxes" />
      </section>
    </Layout>
  )
}
export const query = graphql`
  {
    allStrapiBoxes {
      nodes {
        id
        Description
        Title
        Slug
        Info
        category
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        Os {
          id
          title
        }
      }
    }
  }
`

export default Box
